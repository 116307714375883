import { LayoutQueryFunction } from '../layout'
import { getClient } from '../../apollo/client'
import { gql } from '@apollo/client'
import { AlertQuery } from './__generated__/alert'

export type AlertGlobals = AlertQuery

export const alertGlobalsDefault: AlertGlobals = {}

export const ALERT_QUERY = gql`
  query AlertQuery {
    globalSet (handle: "alertsTab") {
      ... on alertsTab_GlobalSet {
        name
        globalAlerts {
          ... on globalAlerts_BlockType {
            cookieAlert
            alerts {
              ... on alerts_globalAlert_BlockType {
                enableAlert
                alertStyle
                alertContent
              }
            }
          }
        }
      }
    }
  }
`

export const getAlertGlobals: LayoutQueryFunction<AlertGlobals> = async () => {
  const { data } = await getClient().query({
    query: ALERT_QUERY,
  })

  return data
}