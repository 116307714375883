import React, { ComponentProps, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import Image from 'next/image'
import regularDesktop from './images/regular-desktop.svg'
import regularMobile from './images/regular-mobile.svg'
import stickyDesktop from './images/sticky-desktop.svg'
import stickyMobile from './images/sticky-mobile.svg'
import { useMediaQuery } from '@material-ui/core'

export type CreditUnionLogoProps = Omit<ComponentProps<typeof Image>, 'src' | 'alt' | 'blurDataURL'> & {
  notext?: boolean
}

const StyledLogoContainer = styled.figure`
  margin: 0;
  display: flex;
  position: relative;
`

export const CreditUnionLogo: React.FC<CreditUnionLogoProps> = ({ notext, ...props }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const img = useMemo(() => {
    if (isDesktop) {
      return notext ? stickyDesktop : regularDesktop
    } else {
      return notext ? stickyMobile : regularMobile
    }
  }, [notext, isDesktop])

  return (
    <StyledLogoContainer>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={img} { ...props } alt={''} />
    </StyledLogoContainer>
  )
}

export const StyledCreditUnionLogo = styled(CreditUnionLogo)`
  transition: all 0.2s ease;
`
