import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import getConfig from 'next/config'
import deepmerge from 'deepmerge'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

const { serverRuntimeConfig } = getConfig()

const uriMap: Record<string, string> = {
  'localhost': 'http://localhost:8000/graphql',
  'creditunion.atomix.dev': 'https://cms.creditunion.atomix.dev/graphql',
  'creditunion.uat.atomix.dev': 'https://cms.creditunion.uat.atomix.dev/graphql',
  'creditunionsa.com.au': 'https://cms.creditunionsa.com.au/graphql',
  'www.creditunionsa.com.au': 'https://cms.creditunionsa.com.au/graphql',
}

export let defaultUri = process.env.GRAPHQL_URL || process.env.NEXT_PUBLIC_GRAPHQL_URL

let uriSource = 'env'

if (!defaultUri) {
  console.warn('Missing GraphQL url, attempting to resolve automatically')

  if (typeof window !== 'undefined' && window.location.host in uriMap) {
    defaultUri = uriMap[window.location.host]
    uriSource = 'uri-map'
  } else {
    console.warn('Failed to resolve GraphQL url, falling back to staging')
    defaultUri = 'https://cms.creditunion.atomix.dev/graphql'
    uriSource = 'hard-coded'
  }
}

if (process.env.NODE_ENV !== 'production') {
  console.log(`Using GraphQL URL: ${defaultUri} [${uriSource}]`)
}

const cache = new InMemoryCache({
  possibleTypes: serverRuntimeConfig.possibleTypes,
})

export type GetClientOptions = Omit<ApolloClientOptions<NormalizedCacheObject>, 'cache'>

const defaultOptions: GetClientOptions = process.env.NODE_ENV === 'production' ? {} : {
  headers: {
    'x-default-site-url': 'http://nginx:80',
  },
}

export const getClient = ({ uri = defaultUri, ...options }: GetClientOptions = {}) => {
  return new ApolloClient({
    cache,
    ...deepmerge(defaultOptions, options),
    link: ApolloLink.split(
      (op) => op.getContext().batch,
      new BatchHttpLink({ uri }),
      new HttpLink({ uri }),
    ),
  })
}

export const getClientPreviewUri = ({ token, preview, uri = defaultUri }: { token: string; preview: string; uri?: string }) => `${uri}?token=${token}&x-craft-preview=${preview}`

export const client = getClient()