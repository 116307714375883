import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTheme } from 'styled-components'
import LazyHydrate from 'react-lazy-hydration'

export const LazyComponent: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <LazyHydrate whenVisible={!isDesktop} whenIdle={isDesktop}>
      {children}
    </LazyHydrate>
  )
}